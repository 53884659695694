import React ,{useEffect,useState}from "react";
import Banner from "../components/banner";
import BackgroundImage from "../components/background_image";
import CompraTickets from "../components/tickets_compra";
import Card from "../components/card_atracciones";
import Mapa from "../components/mapa";
import PreFooter from "../components/prefooter";
import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";
import HeaderCard from "../components/header_card";
import ContentCard from "../components/content_card";
import CardEvento from "../components/card_eventos";
import CardEventoContenido from "../components/card_evento_contenido";
import ContentInstagram from "../components/content_instragram";
import ReservacionesHotel from "../components/reservaciones_hotel";
import CollageGaleria from "../components/collage_galeria";
import $ from 'jquery';
import Tarifas from "../components/tarifas";
import CardComplementa from "../components/card_complementa";
import ImagenSitioInteres from "../components/imagen_sitio_interes";
import SitioInteres from "../components/sitio_de_interes";
import ComplementaVisita from "../components/complementa_visita";
import CarouselCardComplementa from "../components/carousel_card_complementa";
import CompraTicketsMobile from "../components/tickets_compra_mobile";
import FooterSocial from "../components/footer_social";
import CardCarrito from "../components/card_carrito";
import ListaCarrito from "../components/lista_carrito";
import * as app from "../fetch/fetchApi";

/*export class Header extends React.Component {


    render(){
        <div>
        <Banner />
        <BackgroundImage />
        
        </div>
    }
}*/

/*const Header = () => {
    return (
        <div>
        <PreFooter />
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter />
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
    );
  };
  */

  /*const Header = () => {
    return (
        
        <main className={'row l-main header-visible'}>
            <div className={' main columns'}>
        <section className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
            <div className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
                <HeaderCard />
                <ContentCard />
                <div class="view-footer"> <a href="/atracciones" class="more-content btn-tex-mob-sb">Ver todos las atracciones</a></div>
            </div>
        </section> 
        </div>
        </main>
       
    );
  };*/
/*
  export class Cart extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
          data: undefined,
          dataInstagram:undefined,
          dataEvento:undefined,
          dataMenu:undefined,
          dataMenuFooter: undefined,
          dataTickets:undefined
        };
    }


    componentDidMount(){

      $( "#map-container" ).click(function() {
        alert( "Handler for .click() called." );
      });

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
           }
        };
          fetch("https://estacas.dev.m22.mx/api/v1/views/cards_home_atracciones?display_id=services_1&format_output=0  ",requestOptions)
        .then((response) => {
          console.log(response.status);
          if(response.status===200) {
            //console.log(response.json())
            var data= response.json();
            console.log(data)
            
            return data;
          } else {
           return false;
          }
        })
        .then((recurso) => {
          console.log(recurso)
          if (recurso!==undefined) {
            this.setState({
                data: recurso,
              }); 
          }
          
    })

    const requestOptions2 = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
       }
    };
      fetch("https://estacas.dev.m22.mx/api/v1/views/instagram_slider?display_id=services_1&format_output=0",requestOptions2)
    .then((response) => {
      console.log(response.status);
      if(response.status===200) {
        //console.log(response.json())
        var data= response.json();
        console.log(data)
        
        return data;
      } else {
       return false;
      }
    })
    .then((recurso) => {
      console.log(recurso)
      if (recurso!==undefined) {
        this.setState({
            dataInstagram: recurso,
          }); 
      }
      
})


const requestOptions3 = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json',
 }
};
fetch("https://estacas.dev.m22.mx/api/v1/views/evento_home_2019?display_id=services_1&format_output=0",requestOptions3)
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataEvento: recurso,
    }); 
}

})



fetch("https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=live1_200940_OVebiQlXpdNyaj2ArqGfUsYC&redirect_uri=https://lasestacas.com/&response_type=code")
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)

})

const requestOptions5 = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': 'http://localhost:8000/',
 }
};
fetch("https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/main-menu")
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenu: recurso,
    }); 
}

})


fetch("https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/menu-foooter-menu-2019")
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenuFooter: recurso,
    }); 
}

})



  fetch('https://silvio.estacas.dev.m22.mx/node-api/views/prueba_tickets_componente?display_id=services_1&format_output=0')
  .then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataTickets: recurso,
    }); 
}

})

}
      render(){
          let datos = this.state.data;
          let datosInstagram = this.state.dataInstagram;
          let datosEventos = this.state.dataEvento
          let datosMenu = this.state.dataMenu
          let datosMenuFooter = this.state.dataMenuFooter
          let datosTickets = this.state.dataTickets
          if (datos===undefined||datosInstagram===undefined||datosEventos===undefined||datosMenu===undefined||datosMenuFooter===undefined
            ||datosTickets===undefined) {
             return <h1>Loading...</h1>;
          }else{
           /* return(
              <div>
              
                <Banner />
                <ImagenSitioInteres />
                <SitioInteres />
                
                <CarouselCardComplementa />
                <div>
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter />
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
              </div>
            )
    return (
        <div>
   {/*<div className="mb-5">
   <Banner data={datosMenu}/>
    </div> *


<div className="mt-5">
<ListaCarrito />
</div>

      
        
  
        
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            
            <MenuFooter data={datosMenuFooter}/>
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        <span id={'mostrarTooltip'} data-selector="tooltip-kxqp5vmd1" id="tooltip-kxqp5vmd1" class="tooltip tip-top custom-open"  role="tooltip" style={{visibility: 'visible', width: 'auto', inset: '610.594px auto auto 739.969px', }}>En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.<span class="nub"></span></span>
        </div>
    );
  };
}
  }
*/


const Cart = () => {

  const [data, setData] = useState({})
  const [dataMenu, setDataMenu] = useState({})

  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setData(resultData)
      })
       // set data for the number of stars
  }, [])

  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/m22_item_menu/main-menu`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenu(resultData)
      })
       // set data for the number of stars
  }, [])
  return (
    <div>
       <Banner data={dataMenu} />
  
    <footer className={'l-footer panel'} role={'contentinfo'}>
    <div className={'footer'}>
    
    <MenuFooter data={data}/>
    <Politicas />
    <Redes />
    </div>
    <Copyright />
</footer>
</div>
  );
};

export default Cart;